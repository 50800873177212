/*
Usage Example:

<script lang="ts" setup>
import useHover from '@/components/utils/Hover'

const emit = defineEmits<{
  (event: 'mouseenter'): void
  (event: 'mouseleave'): void
}>()
const props = withDefaults(defineProps<{}>(), {})

const hover = useHover(emit, props)
</script>

<template>
  <!-- classes as array -->
  <div :class="['tw-component', hover.class]" v-bind="˜{ ...hover.on }">
    <!-- component content -->
  </div>

  <!-- classes as object -->
  <div class="tw-component" :class="{ ...hover.classes }" v-bind="˜{ ...hover.on }">
    <!-- component content -->
  </div>
</template>
*/
export interface HoverEmits {
  (event: 'mouseenter'): void
  (event: 'mouseleave'): void
}

export interface HoverProps { }

export const IsHoveredClass = 'is--hovered'

export default function useHover(emit: HoverEmits, props: HoverProps) {
  const internalState = ref(false)
  const state = computed({
    get() {
      return internalState.value
    },
    set(v: boolean) {
      internalState.value = !!v
      if (state.value) emit('mouseenter')
      else emit('mouseleave')
    },
  })
  return {
    emit,
    props,
    state,
    class: computed(() => (state.value ? IsHoveredClass : false)),
    classes: computed(() => ({ [IsHoveredClass]: state.value })),
    events: {
      mouseenter() {
        state.value = true
      },
      mouseleave() {
        state.value = false
      },
    },
  }
}
